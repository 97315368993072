import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import config from './utils/config';
import Home from './components/home';
import Index from './components/index';
import ForgotPassword from './components/forgot-password';
import Login from './components/login-router';
import Signout from './components/signout';
import Terms from './components/terms-conditions';
import * as svg4everybody from 'svg4everybody';

const oktaAuth = new OktaAuth(config.signInConfig);
const host = document.getElementsByTagName('base')[0].href;
const App = () => {
    svg4everybody();
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        const authentiate = async () => {
            try {
                if (oktaAuth.token.isLoginRedirect()) {
                    const res = await oktaAuth.token.parseFromUrl();
                    oktaAuth.tokenManager.setTokens(res.tokens);
                }
                const accessToken = oktaAuth.getAccessToken();
                if (accessToken) {
                    localStorage.setItem("oktaAccessToken", accessToken);
                    const userInfo = await oktaAuth.token.getUserInfo();
                    if (userInfo && location.pathname !== '/terms') {
                        localStorage.setItem("userInfo", JSON.stringify(userInfo));
                        navigate('/');
                    }

                } else {
                    if (location.pathname === '/')
                        redirectToLogin();
                }
            }
            catch (err) {
                console.log(err);
                redirectToLogin();
            }
        };
        authentiate();
    }, [oktaAuth]);
    const redirectToLogin = () => {
        navigate('/login');
    };
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        window.location.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    };


    return (
        <Security
            oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
                <Route path="/implicit/callback" component={Home} /> /
                <Route path="/login" element={<Login baseUrl={host} />} />
                <Route path="/reset-password/:id" element={<Login baseUrl={host} />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/who" element={<Index />} />
                <Route path="/terms" element={<Terms nobutton={true} />} />
                <Route path="/signout" element={<Signout />} />
                <Route path="/login/signout" element={<Signout />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
        </Security>
    );
}

export default App;

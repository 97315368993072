import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import Icon from '../css/Icon';

const Apps = ({ programs }) => {
    const [collapseID, setCollapseID] = useState(programs.length === 1 ? "collapse0" : "");

    const toggleCollapse = (index) => { // Accept index as a parameter
        setCollapseID(prev => (prev === `collapse${index}`) ? "" : `collapse${index}`);
    };

    return (
        <Accordion defaultActiveKey={programs.length === 1 ? 0 : (programs.length + 1)} >
            {programs.map((application, index) =>
                <Accordion.Item eventKey={index} style={{ border: 'none', backgroundColor: '#ffffff' }} key={index}>
                    <Card style={{ border: 'none', backgroundColor: '#ffffff' }} key={index}>
                        <Accordion.Header
                            as={Card.Header}
                            onClick={() => toggleCollapse(index)} // Pass index as a parameter to toggleCollapse
                            style={{ backgroundColor: '#ffffff', border: 'none' }}>
                            <a style={{ color: '#000000', cursor: 'pointer' }} href="#!" onClick={(e) => e.preventDefault()}>
                                <div className="indi-long-form-text h3">
                                    {application.title}
                                    {collapseID === `collapse${index}` ?
                                        <Icon name="caret-up" class="indi-form__select-open-icon" /> :
                                        <Icon name="caret-down" class="indi-form__select-open-icon" />
                                    }
                                </div>
                            </a>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body>
                                {application.programs.map((program, idx) =>
                                    <div className="program-box" key={idx}>
                                        {program.outageMessage !== null &&
                                            <span aria-label={program.title + " program information"} className="program-body">
                                                <span className="program">{program.title}</span>
                                                <span> - {program.description}</span> <br />
                                                <span className="indi-notification--error">{program.outageMessage}</span>
                                            </span>
                                        }
                                        {program.outageMessage === null && (program.link !== null) &&
                                            <a title={program.title + " (opens in new window)"} aria-label={program.title + " program information"} className="program-body" target="_blank" href={program.link}>
                                                <span className="program">{program.title}</span>
                                                <span> - {program.description}</span>
                                            </a>}
                                        {program.outageMessage === null && (program.link === null) &&
                                            <div className="program-body" >
                                                <span className="program">{program.title}</span>
                                                <span> - </span>
                                                <span dangerouslySetInnerHTML={{ __html: program.description }} />
                                            </div>}
                                    </div>
                                )}
                            </Card.Body>
                        </Accordion.Body>
                    </Card>
                </Accordion.Item>
            )}
        </Accordion>
    );

};

export default Apps;

import React, { Component } from 'react';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import Icon from '../css/Icon';
export default class modal extends Component {
    render() {
        return (
            <div className="indi-modal indi-modal--is-open" style={{ paddingTop: '25px' }}>
                <div className="indi-modal__content" style={{ width: '900px' }}>
                    <div className="indi-modal__header" style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                        <div>TERMS & CONDITIONS FOR ACCESS TO AND USE OF
                            UNIVERSAL SERVICE ADMINISTRATIVE COMPANY (USAC) ONLINE SERVICES
                        </div>
                    </div>
                    <div ref={this.termsDidMount} style={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}>
                        <TermsToPrint ref={el => (this.componentRef = el)} />
                        <div className="indi-modal__footer" style={{ paddingBottom: '15px' }}>
                            <div className="indi-row" style={{ textAlign: 'left' }}>
                                <ReactToPrint content={() => this.componentRef}>
                                    <PrintContextConsumer>
                                        {({ handlePrint }) => (
                                            <button className="indi-button print" onClick={handlePrint} style={{ border: 'none', background: 'transparent', padding: '10px 10px 15px 15px', marginBottom: '10px' }}>
                                                <Icon name="print" class="indi-icon" />
                                                &nbsp; Print Terms and Conditions </button>
                                        )}
                                    </PrintContextConsumer>
                                </ReactToPrint>
                            </div>
                            {this.props.nobutton === undefined &&
                                <div className="indi-row">
                                    <div className="indi-col-xs-6" style={{ textAlign: 'left' }}>
                                        <button className="indi-button" onClick={() => this.props.cancelDisclaimer()}>Reject</button>
                                    </div>
                                    <div className="indi-col-xs-6">
                                        <button className="indi-button indi-button--primary" onClick={() => this.props.acceptDisclaimer()}>Accept and Agree</button>
                                    </div>
                                </div>}
                        </div >
                    </div>

                </div >
            </div >
        );
    }
}

class TermsToPrint extends Component {

    render() {
        return (<div className="indi-modal__body">
            <div className="indi-long-form-text">
                <div className="indi-long-form-text__p--small"
                    style={{ fontSize: '16px', lineHeight: '28px', fontStyle: 'italic' }}>
                    <strong> Last Updated 03-16-2022 </strong>
                </div> <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    These Terms and Conditions for Access and Use (Terms & Conditions) apply to all systems, networks, applications,
                    third-party software, and related services (collectively, Online Services) provided by or on behalf of the Universal Service
                    Administrative Company (USAC) to support the federal Universal Service Fund (USF) programs and other programs as directed by
                    the Federal Communications Commission (FCC) that help entities and individuals across the United States obtain access to
                    telecommunications and broadband services.  These programs include the Schools and Libraries Program (E-rate), the Emergency Connectivity Fund (ECF), 
                    the Rural Health Care Program, the Lifeline Program, the Affordable Connectivity Program (ACP)<sup>1</sup>, the High Cost Program,
                    online processes related to the collection of USF contributions, and other benefit programs the FCC directs USAC to
                    administer (collectively USAC administered programs). All Online Services are operated under the authorization of the FCC and
                    all data are deemed to be FCC records.
                            </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    Signing into USAC Online Systems through One Portal does not establish your eligibility for any program administered by USAC.
                    The Online Services can only be accessed by users who register with USAC for, and maintain, an account in good standing for
                    one or more of the USAC administered programs (Users) and by Users' authorized users. Users must register separately for access
                    to each program for which they are eligible to participate.
                    </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    More information about the Online Services, including registration, program eligibility and categories of Users, the ability
                    of Users to authorize additional users to access the Online Services, and Online Service Use Guides for each USAC administered
                    program, is available on each program's page.<strong> In addition, certain Online Services have unique Terms & Conditions applicable
                    only to that service. Users of the National Lifeline Eligibility Verifier (National Verifier), and the National Lifeline Accountability
                    Database (NLAD) should be certain to read the sections of these
                    Terms & Conditions uniquely applicable to those three databases. Acceptance of these Terms & Conditions include acceptance of the
                    unique Terms & Conditions for those Online Services.</strong>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    BY REGISTERING FOR, ACCESSING, OR USING THE ONLINE SERVICES, AS A USER OR AS AN AUTHORIZED USER YOU CONFIRM THAT:
                                <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="1." style={{ margin: '0' }}>YOU ARE AT LEAST 18 YEARS OLD; </li>
                        <li data-index="2." style={{ margin: '0' }}>YOU ACCEPT AND WILL COMPLY WITH THE USAC ONLINE SERVICE USE GUIDE FOR THE SPECIFIC USAC SYSTEM YOU ACCESS AND USE; </li>
                        <li data-index="3." style={{ margin: '0' }}>YOU HAVE READ THIS AGREEMENT; AND </li>
                        <li data-index="4." style={{ margin: '0' }}>YOU AND, TO THE EXTENT APPLICABLE, THE COMPANY, ORGANIZATION, OR ENTITY ON WHOSE BEHALF YOU ARE AUTHORIZED TO ACT, ACCEPT THIS AGREEMENT.</li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>IF YOU CANNOT CONFIRM EACH OF (1) THROUGH (4) ABOVE, THEN PLEASE CLICK "I REJECT" BELOW. BY DOING SO, YOU WILL NOT BE PROVIDED ACCESS TO OR USE OF THE ONLINE SERVICES.</strong><br />
                    USAC may amend these Terms & Conditions at any time, with or without prior notice to you. The date of the latest version will
                      appear on the first page of this document. Your continued access to or use of the Online Services signifies your reading and
                     acceptance of any revised terms. No other amendment to or modification of these Terms & Conditions will be binding unless in
                     writing and signed by USAC's duly authorized representative or posted to the site by USAC's duly authorized representative.
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '20px', lineHeight: '28px', textAlign: 'center' }}>
                    <strong>Terms & Conditions Applicable to All Online Services</strong>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>1. Your Right to Access and Use the Online Services </strong><br />
                    Upon your agreement with and your acceptance of these Terms & Conditions for yourself and, if applicable, for the entities and
                    individuals that you are authorized to act on behalf of, USAC will provide you with a user account and with access to the
                    Online Services, including related software for use in accordance with these Terms & Conditions.
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="1.1">&nbsp;USAC and its third-party licensors (if applicable) grant to you a non-exclusive,
                            non-transferable, non-sublicensable, revocable license to use the Online Services and related software for
                            USAC-authorized activities relating to the USF and USAC administered programs in accordance with the applicable
                            provisions of 47 C.F.R. Part 54 (collectively, Authorized Activities), which include:
                                        <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="a." style={{ margin: '0' }}>Electronically entering data;</li>
                                <li data-index="b." style={{ margin: '0' }}>Editing, certifying and signing forms;</li>
                                <li data-index="c." style={{ margin: '0' }}>Uploading and downloading documents or data relating to the USF and USAC administered programs; </li>
                                <li data-index="d." style={{ margin: '0' }}>Updating and revising forms, documents, and data;</li>
                                <li data-index="e." style={{ margin: '0' }}>Responding to inquiries from USAC;</li>
                                <li data-index="f." style={{ margin: '0' }}>Requesting assistance from USAC, including for changes to user accounts;</li>
                                <li data-index="g." style={{ margin: '0' }}>Appealing USAC actions, decisions, and determinations;</li>
                                <li data-index="h." style={{ margin: '0' }}>Verifying the status of requests, submissions, and Authorized Activities;</li>
                                <li data-index="i." style={{ margin: '0' }}>Retrieving reports and historical information; and</li>
                                <li data-index="j." style={{ margin: '0' }}>Activities specifically authorized in the USAC Online Service Guides. </li>
                            </ol>
                        </li>
                        <li data-index="1.2">&nbsp;USAC and its third-party licensors (if applicable) will, at all times relevant to these Terms & Conditions,
                            retain all intellectual property rights, title and interest to the Online Services, and to all content relating to the Online
                            Services and USAC's administration of the USF and USAC administered programs, including but not limited to any and all
                            modifications and derivative works thereof.
                                    </li>
                        <li data-index="1.3">&nbsp;Any software relating to the Online Services is a commercial service product pursuant to FAR Sections 12.212 and 52.227-19.</li>
                        <li data-index="1.4">&nbsp;Your status as a licensee is conditioned upon your agreement not to:
                                        <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="a." style={{ margin: '0' }}>Copy, modify, or reverse engineer the Online Services and related software, make derivative works based upon
                                    the online platform and related software, or use the Online Services and related software to develop any new processes, goods, or services,
                                                without USAC’s express prior written consent; or </li>
                                <li data-index="b." style={{ margin: '0' }}>Sell, license, rent, or transfer the Online Services and related software to any third party.</li>
                            </ol>
                        </li>
                        <li data-index="1.5">&nbsp;Your initial and ongoing authorization to maintain a user account and to access and use the Online Services and related software
                            is conditioned upon your compliance with all applicable federal, state, and local U.S. laws, including those relating to privacy, data security, and
                            breach notification. Your access and use are also conditioned on your compliance with these Terms & Conditions and with USAC policies and practices
                            related to its programs. See USAC Online Service Guides. You acknowledge that those laws, rules, and regulations can, and will, change overtime and
                            that it is your responsibility to be aware of any changes that affect your rights and responsibilities with regard to your use of the Online Services,
                            your Authorized Activities, and your maintenance on your own systems and devices of information relating to the Online Services and
                            your Authorized Activities.</li>
                        <li data-index="1.6">&nbsp;While accessing and using the Online Services, you may be presented with pop-up notifications, warnings, and similar just-in-time
                            notices relating to certain Authorized Activities, such as accessing the Online Services, providing certain types of data, or electronically signing a
                            form. The contents of these notices are part of and subject to these Terms & Conditions and are incorporated herein by reference as if included in their entirety.
                            You must accept and comply with all such notices.</li>
                        <li data-index="1.7">&nbsp;USAC may modify the Online Services, the Authorized Activities, and/or the form or content of notices relating to the Online
                            Services and Authorized Activities at any time, for any reason, with or without notice to you. USAC is not responsible for any direct or indirect
                            costs or expenses that you may incur as a result of its modifications to the Online Services, Authorized Activities, or notices.</li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>2. Your User Account </strong><br />
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="2.1">&nbsp;You represent and warrant that all information that you submit when you create a user account is accurate, current, and complete, and that you will
                                        maintain accurate, current, and complete account information as long as these Terms & Conditions remain in effect.</li>
                        <li data-index="2.2">&nbsp;To the extent that you provide information about other individuals or authorize creation of user accounts for other individuals, you represent that
                            you have the authority to act on behalf of those other individuals and that any information that you provide to USAC about those individuals was obtained and shared in
                                        compliance with applicable federal, state, and local law.</li>
                        <li data-index="2.3">&nbsp;USAC will assign you a unique username and password for your user account.  You shall not disclose your username and password to any other entity
                            or individual or permit any other entity or individual to access the Online Services using your username and password.  You shall not use any other entity or individual
                                        user account, username, or password to access the Online Services. </li>
                        <li data-index="2.4">&nbsp;You will not use any user account other than your own to access the Online Services and engage in Authorized Activities for the USAC administered programs for
                                        which you are eligible. </li>
                        <li data-index="2.5">&nbsp;You are solely responsible for (1) the confidentiality of your account information, including your password; (2) any and all Online Services activities
                            associated with access from your account; (3) compliance with USAC and Online Services policies and practices, including as set forth in these Terms & Conditions and in the
                            USAC Online Service Guides; and (4) the security of the devices and systems that you use to access the Online Services and the data that you retain relating to the USAC
                                        administered programs and Online Services.</li>
                        <li data-index="2.6">&nbsp;You agree that USAC may provide you with any Communications in electronic format including by electronic mail (i.e., email) or posting via the Online
                            Services and such Communications shall be as effective as if sent in paper format by First Class mail.  "Communications" mean any written correspondence, notice, disclosure,
                            document or other information that USAC provides to you regarding your user account, the Online Services, the USAC administered programs, or the USF.
                            The date of an electronic Communication for the purpose of determining any deadline by which you must respond is the later of the date on which the Communication was posted via
                            the Online Services or the date it was emailed to you. <strong> You agree to provide USAC with, and maintain, an accurate and complete e-mail address, administrator information, and
                            all other contact information related to your user account.</strong>  You may request that USAC provide you with a duplicate paper copy of a specific electronic Communication by
                            contacting the USAC Help Desk at <b>(888) 641-8722</b>. </li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>3. Your Usage of USAC Online Services, including Personally Identifiable Information, Activity Monitoring, and Data Sharing</strong><br />
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="3.1">&nbsp;Certain features of the Online Services may allow you to post, submit, store and manage information (Data). By submitting Data to USAC through the Online
                            Services, you represent and warrant to USAC that you have all necessary rights, permissions, licenses and/or consents to collect, retain, and share that Data with USAC for an
                                        Authorized Activity and are doing so in compliance with applicable federal, state, and local laws, rules, and regulations.</li>
                        <li data-index="3.2">&nbsp;You acknowledge and agree that if you use the Online Services to post, submit, store, manage, or retrieve information from or about an individual that
                            directly identifies that individual or that can be combined with other information from our about an individual to identify an individual (collectively, Personally Identifiable
                            Information (PII)), that you have all necessary rights, permissions, licenses and/or consents to do so; that your collection, retention, sharing, and use of that PII is for an
                            Authorized Activity and complies with all applicable federal, state, and local privacy laws. USAC will handle PII that you provide in accordance with the
                                        <a title="USAC Website Privacy Policy (opens in new window)" href="https://www.usac.org/about/privacy-policies/" target="_blank"> USAC Website Privacy Policy</a>. </li>
                        <li data-index="3.3">&nbsp;You acknowledge and agree that, to the extent you provide PII from anyone under the age of 18, you are responsible for complying with laws specifically
                                        applicable to collection of data from minors, including but not limited to the Children’s Online Privacy Protection Act (COPPA).</li>
                        <li data-index="3.4">&nbsp;You acknowledge and consent to USAC's monitoring and tracking of your account activity and your use of the Online Services for purposes relating to
                            providing, operating, securing, maintaining, and improving the Online Services; administering and maintaining the integrity of the USF and USAC administered programs; and complying with
                            USAC’s legal, contractual, and business obligations, including 47 C.F.R. Part 54. USAC will collect, use, secure, share, disclose, and dispose of all monitoring and tracking
                            information, as well as any Data that you provide, in accordance with the <a title="USAC Website Privacy Policy (opens in new window)" href="https://www.usac.org/about/privacy-policies/" target="_blank"> USAC Website Privacy Policy</a> and applicable federal laws, rules and regulations. </li>
                        <li data-index="3.5">&nbsp;You acknowledge and agree that USAC may share Data, PII, and monitoring and tracking information with the FCC, with law enforcement, and with USAC’s
                            third-party service providers as necessary to provide the Online Services; administer the USF and USAC administered programs; comply with USAC’s legal and business obligations, including
                            47 C.F.R. Part 54; respond to statutory and regulatory inquiries; investigate and remediate data incidents and actual and suspected violations of legal and contractual
                            obligations, and these Terms & Conditions; and applicable System of Records Notices; and otherwise as stated in the
                                        <a title="USAC Website Privacy Policy (opens in new window)" href="https://www.usac.org/about/privacy-policies/" target="_blank"> USAC Website Privacy Policy</a>. </li>
                        <li data-index="3.6">&nbsp;You acknowledge that USAC may make certain information that you provide about yourself or that you submit about other entities and individuals available
                            to other users, to the FCC, and to the public.  The information that USAC will make available to others is identified in notices accompanying online forms, applicable System of
                                        Records Notices, and USAC’s Privacy Policy, which are incorporated by reference into these Terms & Conditions.</li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>4. Your Data Security, Confidentiality, and Risk Management Obligations</strong><br />
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="4.1">&nbsp;You must deliver and receive data to and from the Online Services and maintain the security of data retained on your systems and devices in accordance with the
                                    protocols and standards for each program as set forth therein. </li>
                        <li data-index="4.2">&nbsp;You are responsible for procuring, installing, and maintaining all applications, equipment, telephone lines, communications interfaces, software (including web browser)
                                    and other hardware and software necessary to access the Online Services, and USAC is not responsible for any costs or expenses incurred by you in any such activities.</li>
                        <li data-index="4.3">&nbsp;Maintaining the Confidentiality of the Online Services-Related Software and Databases.
                                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="a." style={{ margin: '0' }}>
                                    The Online Services-related software and databases contain confidential and proprietary information of USAC and its licensors. You agree not to use the software and databases
                                    except as expressly permitted by these Terms & Conditions and to maintain reasonable procedures to prohibit disclosure of the software and databases to third parties and to prevent the unauthorized
                                    duplication, misuse, or removal of the software and databases. Without limiting the foregoing, you agree to use the same procedures and degree of care to avoid unauthorized disclosure or use of the
                                    software and databases as you use to protect your own similar information, but in no event using less than a reasonable degree of care.
                                        </li>
                                <li data-index="b." style={{ margin: '0' }}>
                                    Provided, however, that these confidentiality requirements will not apply to any information that (i) is known to you at the time of its communication to you from USAC or its licensors, (ii) has
                                    become publicly known through no wrongful act of yours, (iii) has been rightfully received by you from a third party authorized to make such communication without restriction, (iv) has been independently
                                    developed by you as evidenced by your written records, or (v) is required to be disclosed pursuant to a subpoena or other validly issued administrative or judicial process, provided that you notify
                                    USAC of such request for confidential information within five (5) days of receipt to allow USAC and its licensors a reasonable opportunity to object to and take necessary legal action to prevent such disclosure.
                                    Notice must be provided to USAC's Help Desk at <b>(888) 641-8722</b>.
                                        </li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>5. Prohibited Activities</strong><br />
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="5.1">&nbsp;Misuse of License and Intellectual Property <br />
                            You may not use, or allow, assist, or permit others to use, your user account or the Online Services and related software in any manner inconsistent with your limited rights
                            as a licensee and your obligations under these Terms & Conditions. Specifically, you may not:
                                        <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="a." style={{ margin: '0' }}>Use the Online Services in whole or in part for any purpose except as expressly allowed under these Terms & Conditions (including without limitation allowing any
                                                distribution or sublicense of the Online Services or other access to the Online Services by any person or entity that is not a User in furtherance of the Authorized Activity);</li>
                                <li data-index="b." style={{ margin: '0' }}>Reverse engineer, decompile, disassemble, re-engineer or otherwise create or attempt to create or permit, allow, or assist others to access; determine or create
                                    the source code of the Online Services (except as and only to the extent any of the foregoing restrictions may be permitted by the licensing terms governing use of any open sourced
                                                components included in software related to the Online Services); </li>
                                <li data-index="c." style={{ margin: '0' }}>Copy, modify or reproduce, in whole or in part, or create any derivative works of the Online Services (except with respect to Data); </li>
                                <li data-index="d." style={{ margin: '0' }}>Attempt to gain unauthorized access to the Online Services; and</li>
                                <li data-index="e." style={{ margin: '0' }}>Re-distribute or sublicense the Online Services, any software associated with the Online Services, or any part thereof, to any other party.</li>
                            </ol>
                        </li>
                        <li data-index="5.2">&nbsp;Engaging in Activities Other than Authorized Activities  <br />
                            You may not use, or allow, assist, or permit others to use, your user account or the Online Services and related software, Data, or PII for any purpose inconsistent
                            with the Authorized Activities, the USF, or the USAC administered programs. Specifically, you may not engage in illegal, unauthorized, abusive, deceptive, misleading, or otherwise
                            harmful activities, including but not limited to:
                                        <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="a." style={{ margin: '0' }}>Falsely stating or otherwise misrepresenting information; </li>
                                <li data-index="b." style={{ margin: '0' }}>Using or providing any fraudulent, illegal, misleading or inaccurate information; </li>
                                <li data-index="c." style={{ margin: '0' }}>Defaming, abusing, harassing, or violating the rights of others, including without limitation others' privacy rights or rights of publicity; </li>
                                <li data-index="d." style={{ margin: '0' }}>Accessing or using (or attempting to access or use) another user's account; </li>
                                <li data-index="e." style={{ margin: '0' }}>Transmitting any viruses, worms, Trojan horses, malware, or other items of a destructive nature; </li>
                                <li data-index="f." style={{ margin: '0' }}>Framing or mirroring any portion of the Online Services;</li>
                                <li data-index="g." style={{ margin: '0' }}>Using any robot, spider, site search/retrieval application or other manual or automatic device or process to retrieve, index, data mine, or in any way
                                                reproduce or circumvent the navigational structure, presentation, content, and protections of the Online Services and related software; </li>
                                <li data-index="h." style={{ margin: '0' }}>Using your user account or the Online Services for illegal activity or for activities relating to gambling, adult entertainment, or similar activities;</li>
                                <li data-index="i." style={{ margin: '0' }}>Using the Online Services for personal promotion, including but not limited to activities relating to promoting a personal business or engaging in
                                                commercial activity unrelated to the USF, any USAC administered programs, and Online Services;  </li>
                                <li data-index="j." style={{ margin: '0' }}>Using information from the Online Services for advertising, profiling, demographic or other marketing purposes unrelated to any USAC administered programs and Authorized Activities;</li>
                                <li data-index="k." style={{ margin: '0' }}>Using the Online Services, Data, or PII to track, identify, or re-identify individuals for purposes unrelated to the USAC administered programs and Authorized Activities; or </li>
                                <li data-index="l." style={{ margin: '0' }}>Probing, scanning, or testing the vulnerability of the Online Services, software, and other related intellectual property; breaching the security or
                                    authentication measures of the Online Services, or taking any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Online Services.
                                    Provided, however, that this provision shall not prohibit research that identifies a bug or potential vulnerability to the limited extent that such bug or vulnerability is reported directly
                                                and solely to USAC and not made public unless and until USAC confirms that it has addressed the bug or potential vulnerability.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>6. Your Notification Obligations</strong><br />
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="6.1">&nbsp;
                            You shall notify USAC's Help Desk, immediately at <b>(888) 641-8722</b> or <a href="mailto:incident@usac.org" title="contact usac">incident@usac.org</a> as soon as possible if you become aware of any actual or suspected:
                            <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="1." style={{ margin: '0' }}>
                                    Unauthorized use of your username or password. In addition to notifying USAC about unauthorized use, you must also change your password through your USAC Online
                                    Services Account if you retain access to the account; please call the Client Service Bureau if the unauthorized use prevents you from accessing your account;
                                            </li>
                                <li data-index="2." style={{ margin: '0' }}>Unauthorized access to or use of the Online Services;</li>
                                <li data-index="3." style={{ margin: '0' }}>Use of the Online Services for other than Authorized Activities;</li>
                                <li data-index="4." style={{ margin: '0' }}>Violations of Online Services policies and practices, including as stated in these Terms & Conditions;</li>
                                <li data-index="5." style={{ margin: '0' }}>Violations of any applicable laws with respect to the person or entity's use of the Online Services;</li>
                                <li data-index="6." style={{ margin: '0' }}>Attempts to copy or distribute software or other proprietary information associated with the Online Services; or</li>
                                <li data-index="7." style={{ margin: '0' }}>Actual or suspected data breaches.</li>
                            </ol>
                        </li>
                        <li data-index="6.2">&nbsp;Without regard to whether notification is provided by you, you and any other entities and individuals you are authorized to act on behalf of,
                            agree to cooperate with USAC in its investigation, assessment, and, if necessary, remediation of any suspected or actual incident involving the Online Services,
                                        related software, USAC administered programs, or the USF and provide USAC with all relevant information. </li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>7. Links to Third-Party Sites</strong><br />
                    The Online Services contain links to third-party websites that are not operated by or on behalf of USAC or controlled by USAC (Linked Sites),
                    including a United States Government website that facilitates payments to the USF.  Linked Sites are provided as a convenience to you. When accessing Linked Sites,
                    you should read the Terms and Conditions and Privacy Statement for each Linked Site. You acknowledge that the Linked Sites are not under USAC's control, and that USAC is
                    not responsible for the continuity of operations for Linked Sites and does not endorse the content of Linked Sites, including any products, information or materials contained therein.
                            </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>8. Term and Termination</strong><br />
                    The term of these Terms & Conditions commences upon your agreement to these Terms & Conditions and continues until that agreement is terminated by either party.
                    USAC may terminate its agreement to these Terms & Conditions and/or suspend or terminate your use of or access to the Online Services and your user account at any time,
                    for any reason or no reason, with or without notice to you. You may terminate your agreement to these Terms & Conditions at any time in accordance with the processes in the
                    USAC Online Service Guides [hotlink]. Upon termination of the agreement to these Terms & Conditions: (i) all licenses granted by USAC to you are terminated; (ii) USAC will cease
                    providing you with access to the Online Services; (iii) you shall make no further use of the Online Services; and (iv) your account, username and password will be deactivated.
                                <br /><br />
                    Sections 1, 3, 4, 5, 6, and 9 shall survive termination of the agreement to these Terms & Conditions, together with any other provisions that must survive in order to give proper
                    effect to the intent and obligations of the parties prior to termination.
                            </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>9. Disclaimers and Waivers</strong><br />
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="9.1">&nbsp;TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, BY AGREEING TO THESE TERMS & CONDITIONS, YOU ACKNOWLEDGE AND AGREE THAT THE ONLINE SERVICES
                            AND RELATED SOFTWARE ARE PROVIDED AS IS AND AS AVAILABLE WITH NO WARRANTIES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                            PARTICULAR PURPOSE AND NON-INFRINGEMENT. USAC DOES NOT WARRANT THAT THE ONLINE SERVICES WILL BE ERROR-FREE, OR PROVIDED OR AVAILABLE WITHOUT INTERRUPTION OR WITH CONTINUOUS
                            ACCESS, OR SECURE, OR THAT ERRORS OR DEFECTS CAN OR WILL BE FIXED, AND USAC ASSUMES NO LIABILITY WHATSOEVER FOR THE ONLINE SERVICES, ITS CONTENTS OR YOUR USE OF THE ONLINE
                            SERVICES. THE FOREGOING DISCLAIMERS ALSO APPLY TO USAC'S SUPPLIERS AND LICENSORS OF PRODUCTS AND SERVICES. USAC has no obligation to provide updates, support and/or
                            maintenance with respect to the Online Services. USAC makes no representations or warranties as to the accuracy or completeness of the Online Services, and you accept
                            responsibility for, and acknowledge that you exercise your own independent judgment in your use of the Online Services and any results obtained therefrom.<br /><br />
                            ADDITIONALLY, THE SOFTWARE RELATED TO THE ONLINE SERVICES INCLUDES SOFTWARE LICENSED TO USAC FROM CERTAIN THIRD-PARTY LICENSORS (THIRD-PARTY LICENSORS).
                            THE SOFTWARE IS LICENSED “AS IS” AND USAC AND THE THIRD-PARTY LICENSORS MAKE NO WARRANTY TO YOU IN CONNECTION WITH THE SOFTWARE. USAC AND THE THIRD-PARTY LICENSORS DISCLAIM ANY EXPRESS,
                            IMPLIED OR STATUTORY WARRANTIES IN CONNECTION WITH THE SOFTWARE, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
                            USAC AND THE THIRD PARTY LICENSORS ARE NOT LIABLE FOR ANY DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF THE SOFTWARE, INCLUDING WITHOUT LIMITATION, DIRECT, INDIRECT, CONSEQUENTIAL,
                            INCIDENTAL, AND SPECIAL DAMAGES. THE THIRD PARTY LICENSORS ARE INTENDED THIRD PARTY BENEFICIARIES OF THE PROVISIONS OF THIS AGREEMENT RELATING TO THE THIRD PARTY LICENSOR'S SOFTWARE
                            INCORPORATED INTO THE SOFTWARE.
                                    </li>
                        <li data-index="9.2">&nbsp;You waive any claim that you may have against USAC relating to its use and disclosure of Data, PII, and tracking and monitoring information,
                            except to the extent such claim is caused by USAC's material violation of laws or regulations, willful misconduct, or material violation of these Terms & Conditions or
                                        the <a title="USAC Website Privacy Policy (opens in new window)" href="https://www.usac.org/about/privacy-policies/" target="_blank"> USAC Website Privacy Policy</a>.
                                    </li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>10. Limitation of Liability</strong><br />
                    TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, USAC AND ITS SUPPLIERS AND LICENSORS SHALL NOT BE LIABLE FOR LOSS OR DAMAGE OF LOST PROFITS OR REVENUES OR SIMILAR ECONOMIC LOSS,
                     FOR THE COST OF SUBSTITUTE GOODS OR SERVICES, OR FOR ANY CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT OR PUNITIVE DAMAGES, WHETHER IN CONTRACT, TORT OR OTHERWISE,
                    ARISING OUT OF OR IN CONNECTION WITH THE PERFORMANCE OR NON-PERFORMANCE OF THIS AGREEMENT OR THE AVAILABILITY OR NON-AVAILABILITY OF THE ONLINE SERVICES,
                    EVEN IF USAC HAS BEEN ADVISED OF SUCH CLAIM. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, USAC'S TOTAL AGGREGATE LIABILITY FOR ANY CLAIMS ARISING UNDER THIS AGREEMENT OR
                    OTHERWISE ARISING FROM THE TRANSACTIONS CONTEMPLATED HEREIN REGARDLESS OF THE FORM OF ACTION (INCLUDING, BUT NOT LIMITED TO, ACTIONS FOR BREACH OF CONTRACT, NEGLIGENCE,
                    STRICT LIABILITY, RESCISSION AND BREACH OF WARRANTY) SHALL NOT EXCEED $100.
                            </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>11. Disputes</strong><br />
                    Any disputes or claims that you may bring against USAC under or related to this Terms & Conditions must be brought consistent with the requirements of 47 C.F.R. Part 54.
                            </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>12. Sole Agreement</strong><br />
                    These Terms & Conditions (including without limitation notices and any other USAC documentation referenced herein) embodies the entire agreement between USAC and you and supersedes
                    all previous and contemporaneous agreements, understandings and arrangements, with respect to your access to and use of the Online Services.
                 </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '20px', lineHeight: '28px', textAlign: 'center' }}>
                    <strong>Unique Terms & Conditions for Certain Online Services</strong>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>1. National Lifeline Eligibility Verifier (National Verifier)</strong><br />
                    These Terms & Conditions apply only to the National Lifeline Eligibility Verifier ("National Verifier") and all National Verifier-related systems and
                    networks (including without limitation the National Verifier web portal and Lifeline Eligibility Database, as modified for the Affordable Connectivity Program) (collectively the "Database") that is provided by USAC. 
                    You may be presented with a warning banner when you access the
                    Database ("Banner"), and the contents of the Banner are a part of this Agreement and incorporated herein by reference as if included in their entirety,
                    and you must accept and comply with the Terms & Conditions of the Banner. The Database was created in support of the federal
                    Universal Service "Lifeline" Program, which provides support for eligible telecommunications companies to offer discounts to eligible low-income consumers to
                    help them initiate and maintain telecommunications and broadband services at affordable rates.  The Database also supports and serves a similar purpose
                    for other programs that use income or consumer participation in certain government benefit programs as eligibility criteria, such as the Affordable Connectivity Program.<br /> <br />
                    USAC allows six types of subscribers to access and use the Database, each of which is a "Subscriber" for purposes of this Agreement: (1) service providers
                    participating in Lifeline and similar programs that use income or consumer participation in certain government benefit programs as eligibility criteria,
                    such as the Affordable Connectivity Program (“Providers”); (2) individuals applying for Lifeline or similar programs that use income or consumer
                    participation in certain government benefit programs as eligibility criteria, such as the Affordable Connectivity Program ("Customers"); (3) employees,
                    agents or subcontractors of USAC ("USAC Agents"); (4) State, territory, or District of Columbia agencies and other authorized entities and their agents
                    including State public utility commissions, State departments of health and human services, social service agencies and other third parties approved by
                    USAC for purposes of assisting individuals in applying for Lifeline support  or support from other programs that use income or consumer participation in 
                    certain government benefit programs as eligibility criteria, such as the Affordable Connectivity Program ("State" or "States"); (5) United States government agencies 
                    ("Agency" or "Agencies"); and (6) representatives from Tribal Nation agencies ("Tribal Representatives"). This Agreement defines the relationships between both USAC and
                    (i) the Subscriber, and (ii) each person who accesses the Database as authorized by and on behalf of the Subscriber. Both Subscriber and each person who
                    accesses the Database are referred to as "you" (and variations such as "your") in this Agreement.
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="1.1">&nbsp;Access and Use; Restrictions and Rights
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="1.1.1">&nbsp;&nbsp;&nbsp;&nbsp;Right to Access and Use. Subject to the Terms & Conditions of this Agreement,
                                    USAC shall make the Database available to you for access within the United States solely for the Authorized Purpose, defined
                                    below.</li>
                                <li data-index="1.1.2">&nbsp;&nbsp;&nbsp;&nbsp;For Subscribers that are Providers, the "Authorized Purpose" means use of the
                                    Database by your Authorized Users (defined in Section 1.2) in accordance with 47 C.F.R. Part 54,  Subparts E, P and R  solely in
                                    response to a current or prospective Customer's request to (i) query the
                                    Database to determine whether the prospective Customer is eligible to receive Lifeline, Affordable Connectivity Program, or other benefits; (ii) assist prospective
                                    Customer with the application form including the uploading of eligibility documentation; (iii) update an existing Customer's information;
                                    (iv) check the status of an eligibility application; (v) respond to inquiries from current or prospective Customers on eligibility
                                    including dispute resolution; (vi) initiate and conduct Lifeline reverification and recertification processes; (vii) conduct reviews, data
                                    entry and other process for manually submitted certifications, re-certifications and related documentation and (viii) enter personal
                                    information and eligibility documentation received through the mail or via a telephone request. USAC Agents and Agencies, in accordance
                                    with 47 C.F.R. Part 54, Subparts E, P and R, may also run queries and reports.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="1.2">&nbsp;User Accounts
                            <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="1.2.1">&nbsp;&nbsp;&nbsp;&nbsp;USAC Agents, State, Agency and Tribal Representative users must establish a unique National Verifier
                                    account for each person authorized by it to use the Database in furtherance of the Authorized Purpose, each of whom shall be, for purposes of
                                    this Agreement, an "Authorized User" of USAC, the State, Agency, or Tribal Nation, as applicable.</li>
                                <li data-index="1.2.2">&nbsp;&nbsp;&nbsp;&nbsp;Providers must establish a unique account using National Lifeline Accountability Database (NLAD) for
                                    each person authorized by it to use the Database in furtherance of the Authorized Purpose, each of whom shall be, for the purposes of this Agreement,
                                    an "Authorized User" of the Carrier.  Representative agents must establish a unique account in the Representative Agent Database (RAD).</li>
                                <li data-index="1.2.3">&nbsp;&nbsp;&nbsp;&nbsp;Each Authorized User will have a user identification and password, and no one else
                                    may use this information to access the Database. You represent and warrant that all information you submit when you create a
                                    user account is accurate, current and complete, and that you shall keep your account information accurate, current and complete.
                                    You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including passwords,
                                    and for any and all activity that occurs under your account. You may not use the user identification or password of another account at
                                    any time, and you shall notify USAC immediately at <a target="_blank" title="notify usac" href="mailto:incident@usac.org">incident@usac.org</a> if 
                                    you suspect any unauthorized use of a user identification or password. Authorized Users may access and use the Database solely in furtherance of
                                    their respective Subscriber's performance of the Authorized Purpose.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="1.3">&nbsp;Data Privacy <br />
                            Certain features of the Database may allow you to post, submit, store and manage personally identifiable information or consumer
                            information ("Consumer Data"). By submitting Consumer Data to the Database, you represent and warrant to USAC that you have all
                            necessary rights, permissions, licenses and/or consents to do so. You acknowledge that your access to and use of the Database
                            depends on compliance with applicable federal and state privacy laws and regulations, and that the laws can and will change over time.
                            You acknowledge that USAC shall monitor and track your activity with respect to the Database, and you consent to such monitoring
                            and tracking. The data in the National Verifier is part of federal Privacy Act systems of records.  All uses of your Consumer Data is
                            limited to the uses authorized by the Privacy Act, , 5 U.S.C. § 552a(b)(1)-(12), the system of records notices ("SORNs"), and the
                            Privacy Act Statement USAC shall treat such information, along with Consumer Data, all in accordance with the Privacy Act, the SORNs,
                            and USAC's privacy policy found on its website. You acknowledge that USAC may disclose information about you and/or your Consumer
                            Data for the purposes of administering the Lifeline and similar programs that use income or consumer participation in
                            certain government benefit programs as eligibility criteria, such as the Affordable Connectivity Program, operating the Database, and for any other routine use as described in the
                            SORNs. You waive any claim you may have against USAC relating to its use of the foregoing information, except to the extent such
                            claim is caused by USAC's willful misconduct.
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="1.4">&nbsp;Reservation of Rights <br />
                            As between the parties, USAC shall at all times solely and exclusively own all right, title, and interest in and to the Database, and all
                            content in the foregoing, and all intellectual property or other rights in the foregoing, including but not limited to any and all
                            modifications and derivative works. No licenses are granted by implication or estoppel, and all rights not expressly granted by USAC
                            herein are expressly reserved by USAC.
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="1.5">&nbsp;Communications Protocols <br />
                            You must deliver and receive data to and from the Database via protocols and standards determined by USAC. You are responsible for procuring,
                            installing, and maintaining all applications, equipment, telephone lines, communications interfaces, software (including web browser) and
                            other hardware and software necessary to access the Database and USAC is not responsible for any costs or expenses incurred by you in any
                            such activities. You shall comply with all laws, rules and regulations applicable to your use of the Database including without limitation
                            all applicable privacy laws, rules and regulations. Except as allowed by your Authorized Purpose, you shall keep all information disclosed
                            or made available by USAC to you hereunder, including without limitation the information received from the Database, in strict confidence,
                            and shall not disclose, transmit, inform or make available such information to any entity or person, except to Authorized Users.
                            You may use such information only in furtherance of an Authorized Purpose. <br /> <br />
                            You agree that USAC may provide you with any Communications in electronic format including by electronic mail (i.e., email) or by posting
                            online in the National Verifier and that USAC may discontinue sending Communications it has formerly sent in paper format by First Class mail.
                            "Communications" mean any written correspondence, notice, disclosure, document or other information that USAC provides to you, or that you sign
                            or submit or agree to. The date of an electronic Communication for the purpose of determining any deadline by which you must respond is the
                            later of the date on which the Communication was posted in the National Verifier or the date it was emailed to you. You agree to provide USAC
                            with an accurate and complete e-mail address, administrator information and all other contact information related to your User Account and to
                            update this information as soon as it changes. You may request that USAC provide you with a duplicate paper copy of a specific electronic Communication
                            by contacting USAC at 877-524-1325. If there is a response deadline associated with this Communication, the response deadline shall be calculated
                            from the date it was posted in the National Verifier or emailed to you. USAC reserves the right, but assumes no obligation, to provide a paper
                            (instead of electronic) copy of any Communication. In that event, if there is a response deadline associated with this Communication, the deadline
                            shall be calculated from the date that appears on the Communication (i.e., letter date).
                        </li>
                    </ol>
                </div>
                <br />
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <strong>2. National Lifeline Accountability Database (NLAD)</strong><br />
                    These Terms & Conditions apply only to the National Lifeline Accountability Database ("NLAD") and all NLAD-related systems and networks
                    (including without limitation the NLAD web portal, as modified for the Affordable Connectivity Program) (collectively the "Database")
                    that is provided by the Universal Service Administrative Company ("USAC"). The Database was created in support of the federal Universal
                    Service Lifeline Program, which provides support for telecommunications companies to offer discounts to eligible low-income consumers to
                    help them initiate and maintain telecommunications services at affordable rates.  The Database also supports and serves a similar purpose
                    for other programs that use income or consumer participation in certain government benefit programs as eligibility criteria, such as the
                    Affordable Connectivity Program.
                    <br /> <br />
                    USAC allows three types of subscribers to access and use the Database, each of which is a "Subscriber" for purposes of this Agreement:
                    (1) service providers participating in Lifeline and similar programs that use income or consumer participation in certain government benefit
                    programs as eligibility criteria, such as the Affordable Connectivity Program (“Providers”) (2) agencies or agents of a State, to include
                    but not be limited to, state public utility commissions, state social service agencies, and their authorized agents ("State" or "States"),
                    and (3) United States federal government agencies and Tribal representatives ("Agency" or "Agencies"). This Agreement defines the
                    relationships between both USAC and (i) the Subscriber, and (ii) each person who accesses the Database as authorized by and on behalf of the
                    Subscriber. Both Subscribers and each person who accesses the Database are referred to as "you" (and variations such as "your") in this
                    Agreement.
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="2.1">&nbsp;Access and Use; Restrictions and Rights <br />
                            Right to Access and Use. Subject to the Terms & conditions of this Agreement, USAC will make the Database available to you for
                            access within the United States solely for the Authorized Purpose, defined below.
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="2.1.1">&nbsp;&nbsp;&nbsp;&nbsp;For Subscribers that are Providers, the "Authorized Purpose" means use of the
                                    Database by your Authorized Users (defined in Section 1.2) in accordance with the applicable provisions of 47 C.F.R. Part 54
                                     Subparts E, P and R  and related FCC Orders, solely in response to a current or prospective Lifeline or other program Customer's
                                    request to: (i) query the Database to determine whether the  prospective Customer is currently receiving a program benefit
                                    from another Provider, and whether anyone else living at the prospective Customer's residential address is currently
                                    receiving a program benefit, and to inform the prospective Customer of the answers to such queries; (ii) enroll a current or
                                    prospective Customer in a program for which they are not receiving a benefit, (iii) update an existing Customer's information;
                                    (iv) transfer the account of a Customer receiving a  benefit from another  Provider to your Carrier (or, in the case of states,
                                    from one carrier to another); (v) de-enroll an existing Customer; and (vi) claim and receive program support based on
                                    subscribers enrolled in the Database. Providers, in accordance with the applicable provisions of 47 C.F.R. Part 54, Subparts E, P, and R, 
                                    and related FCC Orders, may also conduct an initial upload of their Customers to populate the database and thereafter run reports.
                                </li>
                                <li data-index="2.1.2">&nbsp;&nbsp;&nbsp;&nbsp;For Subscribers that are States, the "Authorized Purpose" means using the Database
                                    in accordance with the applicable provisions of 47 C.F.R. Part 54, Subparts E, P and R, solely in response to a prospective
                                    program Customer's request to query the Database to determine whether the prospective program Customer is currently receiving
                                    a benefit from a Provider , and whether anyone else living at the prospective Customer's residential address is currently receiving
                                    a benefit, and to inform the prospective Customer of the answers to such queries. States, in accordance with the applicable
                                    provisions of 47 C.F.R. Part 54, Subparts E, P and R, and related FCC Orders, may also query the Database for the purpose of implementing
                                    and managing the Lifeline Program in their state.</li>
                                <li data-index="2.1.3">&nbsp;&nbsp;&nbsp;&nbsp;For Subscribers that are Agencies, the "Authorized Purpose" means using the Database to oversee
                                    the administration of the Lifeline Program (47 C.F.R. Part 54, Subpart E) and other USAC administered programs (47 C.F.R. Part 54, Subpart P and 47 C.F.R. Part 54, Subpart R )
                                    (including, but not limited to prevention and detection of waste, fraud and abuse) and as required in accordance with 47 C.F.R. Part 54,
                                     Subparts E, P and R . You may be presented with a warning banner when you access the Database ("Banner"), and the contents of the Banner are a part of
                                    this Agreement and incorporated herein by reference as if included in their entirety, and you must accept and comply with the Terms & Conditions of the Banner.
                                    You acknowledge that USAC may modify the Database and/or the Banner at any time and for any reason or no reason, with or without notice to you.
                                    USAC is not responsible for any costs or expenses you may incur as a result of any modifications to the Database or the Banner.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="2.2">&nbsp;User Accounts <br />
                            State and Agency users must establish a unique NLAD account for each person authorized by it to use the Database in furtherance of the
                            Authorized Purpose, each of whom shall be, for purposes of this Agreement, an "Authorized User" of the State or Agency, as applicable.
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="2.2.1">&nbsp;&nbsp;&nbsp;&nbsp;The "Manager" is authorized by the State or Provider and designated by the
                                    commissioner of the State or officer of the Provider to have administrative rights for user access to the Database in furtherance
                                    of the Authorized Purpose. The Manager may in turn authorize employees and independent contractors of its organization having a
                                    need to access the Database in support of the State's or Provider's performance of the Authorized Purpose, to access the
                                    Database for the Authorized Purpose. Each such person is an "Authorized User" of the State or Provider (and the Manager is also
                                    an "Authorized User" of the State or Provider). Except for the API (defined below) Authorized Users, the Manager shall register
                                    its Authorized Users by name, and will designate each Authorized User for a particular account function (e.g., operations or
                                    analyst) as permitted by USAC. The Manager may change its Authorized Users' registrations and designations from time to time.
                                    If USAC or a Subscriber terminates or suspends a Manager's account, this will automatically result in terminating access for
                                    each of that Manager's Authorized Users. If a State or Provider appoints a replacement Manager, the officer or commissioner must
                                    promptly update the Database.</li>
                                <li data-index="2.2.2">&nbsp;&nbsp;&nbsp;&nbsp;USAC will assign each Authorized User a user identification and password, and no one
                                    else may use this information to access the Database. You represent and warrant that all information you submit when you create
                                    a user account is accurate, current and complete, and that you will keep your account information accurate, current and complete.
                                    You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including passwords,
                                    and for any and all activity that occurs under your account. You may not use the user identification or password of another account at any time,
                                    and you shall notify USAC immediately at <a title="report to usac" href="mailto:incident@usac.org">incident@usac.org</a> if you
                                    suspect any unauthorized use of a user identification or password. Authorized Users may access and use the Database solely in
                                    furtherance of their respective Subscriber's performance of the Authorized Purpose.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="2.3">&nbsp;Data and Privacy <br />
                            Certain features of the Database may allow you to post, submit, store and manage personally identifiable information or
                            consumer information ("Consumer Data"). By submitting Consumer Data to the Database, you represent and warrant to USAC
                            that you have all necessary rights, permissions, licenses and/or consents to do so. You acknowledge that your access to
                            and use of the Database and Consumer Data depends on compliance with applicable federal and state privacy laws and regulations
                            and that the laws can and will change over time. You acknowledge that USAC will monitor and track your activity with respect to
                            the Database, and you consent to such monitoring and tracking. Data contained in NLAD is part of federal
                            Privacy Act systems of records. All uses of your Consumer Data are limited to the  uses authorized by the Privacy Act,
                            5 U.S.C. § 552a(b)(1)-(12), the systems of records notices (SORNs), and the Privacy Act Statements located on the programs’
                            application and recertification forms. USAC will treat such information, along with Consumer Data, all in accordance with the
                            Privacy Act, the SORNs, and USAC's privacy notice available on its website. You acknowledge that USAC may disclose information
                            about you and/or your Consumer Data for purposes of administering the Lifeline program and similar programs that use income or consumer
                            participation in certain government benefit programs as qualifying criteria, such as the Affordable Connectivity Program, operating the Database and API, and for any
                            other routine use as described in the applicable SORNs. You waive any claim you may have against USAC relating to its use of the
                            foregoing information, except to the extent that such claim is caused by USAC's willful misconduct.<br /><br />
                            You must notify USAC immediately if you know or have a reasonable suspicion that any person or entity has accessed or used the
                            Database without authorization, or has used the Database other than for an Authorized Purpose. Subscribers shall immediately
                            terminate an Authorized User's access to the Database if such Subscriber knows or has a reasonable suspicion that such Authorized
                            User has breached this Agreement or violated any applicable laws with respect to its use of the Database. If a security incident
                            occurs with respect to the Database, you shall cooperate with USAC in its investigation of such incident and provide USAC with
                            all relevant information.
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="2.4">&nbsp;API <br />
                            USAC may provide States or Providers with an application program interface or "API". A State or Provider may allow only its Manager to
                              install, run and copy the API (either itself or by an Authorized User authorized by the Manager for this specific purpose) at
                             Subscriber's owned or controlled facility solely to develop and maintain an application for the State or Provider that it may use
                             solely to access and use the Database as permitted in this Agreement, and solely for the term of this Agreement. You shall not,
                             nor shall you allow any third party to: (i) decompile, disassemble, or otherwise reverse engineer or attempt to reconstruct or discover
                             any source code or underlying ideas, user interface techniques or algorithms, file formats or programming or interoperability
                             interfaces of the API; (ii) remove any product identification, copyright or other notices; (iii) modify the API or incorporate the API
                             into or with other software or services or make derivative works thereof; or (iv) take any action that would cause the API to be
                             placed in the public domain. USAC may modify the API at any time, with or without notice. USAC is not responsible for any costs or
                             expenses you may incur as a result of any modifications to the API. If you access the Database via the API, then, without limiting any
                             other provision herein, you must comply with the following terms:
                            <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                <li data-index="2.4.1">&nbsp;&nbsp;&nbsp;&nbsp;The requirements for interconnection between the system owned and controlled by the
                                    State or Provider ("Your System") and the Database are for the express purpose of exchanging data between Your System and the
                                    Database. No user services are offered by USAC.</li>
                                <li data-index="2.4.2">&nbsp;&nbsp;&nbsp;&nbsp;The security of the information being passed on this two-way connection is protected
                                    through the use of a Federal Information Processing Standards (FIPS) 140-2 (or FIPS 140-3 if applicable) approved encryption
                                    mechanisms.</li>
                                <li data-index="2.4.3">&nbsp;&nbsp;&nbsp;&nbsp;You may only access the Database and use it in accordance with all applicable laws,
                                    rules and regulations.</li>
                                <li data-index="2.4.4">&nbsp;&nbsp;&nbsp;&nbsp;If you discover a security incident that directly affects (1) the integrity and/or
                                    confidentiality of the data being provided, or (2) the systems or network devices used to interface with the Database, you will report
                                    it in accordance with your incident reporting procedures and also immediately to USAC at <a title="report to usac" href="mailto:incident@usac.org">incident@usac.org</a>.</li>
                                <li data-index="2.4.5">&nbsp;&nbsp;&nbsp;&nbsp;The State or Provider is responsible for logging and auditing its application
                                    processes and user activities involving the interconnection. Activities that will be recorded include event type, date and time
                                    of event, user identification, workstation identification, success or failure of access attempts, and security actions
                                    taken by system administrators or security officers. You must retain audit logs consistent with the
                                    requirements of 47 C.F.R. §§ 54.417, 54.1611.</li>
                                <li data-index="2.4.6">&nbsp;&nbsp;&nbsp;&nbsp;Your System must be described by a name, function, location, description of data
                                    (including sensitivity or classification level), and you must provide such information to USAC promptly upon receipt of written
                                    request.</li>
                                <li data-index="2.4.7">&nbsp;&nbsp;&nbsp;&nbsp;You will designate and provide contact information for your technical lead.
                                    To safeguard the confidentiality, integrity, and availability of the connected systems and the data they store, process, and
                                    transmit, you agree to provide notice of specific events within the time frames indicated below:
                                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                                        <li data-index="2.4.7.1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will immediately notify USAC by sending an email
                                            to <a title="report to usac" href="mailto:incident@usac.org">incident@usac.org</a> when a security incident(s) that
                                            directly affects (1) the integrity and/or confidentiality of the data being provided, and/or (2) the systems or network
                                            devices used to interface with the Database is detected, so USAC may take steps to determine whether its system has
                                            been compromised and to take appropriate security precautions. You shall cooperate with USAC in its investigation of
                                            any security incident.</li>
                                        <li data-index="2.4.7.2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will provide prompt notification of any
                                            changes in technical point of contact information by updating the Database.</li>
                                    </ol>
                                </li>
                                <li data-index="2.4.8">&nbsp;&nbsp;&nbsp;&nbsp;Modifications to either system that are necessary to support the interconnection are
                                    the responsibility of the respective system owners' organization.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="2.5">&nbsp;Reservation of Rights <br />
                            As between the parties, USAC will at all times solely and exclusively own all right, title, and interest in and to the Database,
                            the API, and all content in the foregoing, and all intellectual property or other rights in the foregoing, including but not limited
                            to any and all modifications and derivative works. No licenses are granted by implication or estoppel, and all rights not expressly
                            granted by USAC herein are expressly reserved by USAC.
                        </li>
                    </ol>
                </div>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                    <ol style={{ fontWeight: '400', fontSize: '16px' }}>
                        <li data-index="2.6">&nbsp;Communications Protocols <br />
                            You must deliver and receive data to and from the Database via protocols and standards determined by USAC. You are responsible for
                                procuring, installing, and maintaining all applications, equipment, telephone lines, communications interfaces, software
                              (including web browser) and other hardware and software necessary to access the Database and to use the API, and USAC is not
                              responsible for any costs or expenses incurred by you in any such activities. You will comply with all laws, rules and regulations
                              applicable to your use of the Database and/or API, including without limitation all applicable privacy laws, rules and regulations.
                              Except as allowed by your Authorized Purpose, you will keep all information disclosed or made available by USAC to you hereunder,
                              including without limitation the API and information received from the Database, in strict confidence, and shall not disclose,
                              transmit, inform or make available such information to any entity or person, except to Authorized Users. You may use such information
                              only in furtherance of an Authorized Purpose.
                        </li>
                    </ol>
                </div>
                <br/>
                <div className="indi-long-form-text__p--small" style={{ fontSize: '16px', lineHeight: '28px' }}>
                   <sup>1</sup> The Affordable Connectivity Program is the successor program to the Emergency Broadband Benefit Program.
                </div>
            </div> </div>);
    }
}
import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Loader from './loading';

const redirectUri = document.getElementsByTagName('base')[0].href + 'login';

const SignOut = () => {
    const { oktaAuth } = useOktaAuth();
    const [links, setLinks] = useState();

    useEffect(() => {
        try {
            signoutApps();
            localStorage.removeItem("oktaAccessToken");
            localStorage.removeItem("userInfo");
            oktaAuth.signOut({ postLogoutRedirectUri: redirectUri });
        } catch (e) {
            console.log(e);
        }

    }, [oktaAuth]);

    const signoutApps = async () => {
        const response = await
            fetch(document.getElementsByTagName('base')[0].getAttribute('href') + 'Home/SignOut');
        if (response.status !== 200) {
            return;
        }
        const data = await response.json();
        setLinks(data);
    };

    return (
        <div>
            {!links && <Loader />}
            {
                links &&
                <div style={{ display: 'none' }}>
                    {this.state.links.map((link, index) =>
                        <iframe key={index} title={index} src={link}></iframe>
                    )}
                </div>
            }
        </div >
    );
};

export default SignOut;
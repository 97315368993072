import React from 'react';

const Loading = ({ inline }) => {
    if (inline) {
        return (
            <div className="indi-loader indi-loader--inline" aria-label="Loading">
                <ul className="indi-loader__motif">
                    <li className="indi-loader__motif-item indi-loader__motif-item--1"></li>
                    <li className="indi-loader__motif-item indi-loader__motif-item--2"></li>
                    <li className="indi-loader__motif-item indi-loader__motif-item--3"></li>
                    <li className="indi-loader__motif-item indi-loader__motif-item--4"></li>
                </ul>
            </div>
        );
    }
    return (
        <div className="indi-loader indi-loader--overlay" aria-label="Loading">
            <ul className="indi-loader__motif">
                <li className="indi-loader__motif-item indi-loader__motif-item--1"></li>
                <li className="indi-loader__motif-item indi-loader__motif-item--2"></li>
                <li className="indi-loader__motif-item indi-loader__motif-item--3"></li>
                <li className="indi-loader__motif-item indi-loader__motif-item--4"></li>
            </ul>
        </div>
    );
};
export default Loading;
import React, { useState } from 'react';
import Icon from '../css/Icon';
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

const PasswordExpired = ({ transactionChange, transaction }) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({ oldPassword: '', newPassword: '', confirmPassword: '' });

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        switch (name) {
            case 'oldPassword':
                setErrors((prev) => ({ oldPassword: (value.length === 0 ? 'please provide old password.' : ''), ...prev }));
                setOldPassword(value);
                break;
            case 'newPassword':
                setErrors((prev) => ({ newPassword: (value.length === 0 ? 'please provide new password.' : ''), ...prev }));
                setNewPassword(value);
                break;
            case 'confirmPassword':
                setErrors((prev) => ({ confirmPassword: (value === newPassword ? '' : "Passwords don't match."), ...prev }));
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (newPassword === '') {
            setErrors({ newPassword: 'Please provide new password.', confirmPassword: 'Please provide confirm password.', oldPassword: '' });
            return;
        }
        if (validateForm(errors)) {
            setLoading(true);
            transaction.changePassword({
                oldPassword: oldPassword,
                newPassword: newPassword
            }).then(result => transactionChange(result))
                .catch(err => {
                    setLoading(false);
                    setError(err.errorSummary);
                });
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            {transaction &&
                <div>
                    <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                        <div className="indi-long-form-text h5 title">Your password has expired</div>
                        <div className="desc">
                            <br />
                            <span><strong>Password requirements</strong>: at least {transaction.policy.complexity.minLength} characters</span>
                            {transaction.policy.complexity.minUpperCase > 0 && <span>
                                {transaction.policy.complexity.minUpperCase === 1 ? ", one uppercase" : ", " + transaction.policy.complexity.minUpperCase + " uppercase"}</span>}
                            {transaction.policy.complexity.minLowerCase > 0 && <span>
                                {transaction.policy.complexity.minLowerCase === 1 ? ", one lowercase" : ", " + transaction.policy.complexity.minLowerCase + " lowercase"}</span>}
                            {transaction.policy.complexity.minNumber > 0 && <span>
                                {transaction.policy.complexity.minNumber === 1 ? ", one number" : ", " + transaction.policy.complexity.minNumber + " numbers"}</span>}
                            {transaction.policy.complexity.minSymbol > 0 && <span>
                                {transaction.policy.complexity.minSymbol === 1 ? ", one special character" : ", " + transaction.policy.complexity.minSymbol + " special characters"}</span>}
                            {transaction.policy.age.historyCount > 0 && <span> and cannot be any of your last {transaction.policy.age.historyCount} passwords.</span>}
                        </div>
                        {error && <div className="indi-form__input-row"><br />
                            <div className="indi-notification indi-notification--error">
                                <Icon name="warning" class="indi-icon" />
                                {error}
                            </div>
                        </div>}
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">Old password</label>
                            <input aria-label="old password" name="oldPassword" className={"indi-form__input" + (errors.oldPassword.length > 0 ? " indi-form__input--has-error" : "")}
                                style={{ height: '40px' }} type="password"
                                value={oldPassword} onChange={handleChange} />
                            <div className="indi-form__input-notification indi-form__input-notification--has-error">{errors.oldPassword}</div>
                        </div>
                        <hr />
                        <div className="indi-form__input-row">&nbsp;</div>
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">New password</label>
                            <input aria-label="new password" name="newPassword" className={"indi-form__input" + (errors.newPassword.length > 0 ? " indi-form__input--has-error" : "")}
                                style={{ height: '40px' }} type="password"
                                value={newPassword} onChange={handleChange} />
                            <div className="indi-form__input-notification indi-form__input-notification--has-error">{errors.newPassword}</div>
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">Repeat password</label>
                            <input aria-label="confirm password" name="confirmPassword" className={"indi-form__input" + (errors.confirmPassword.length > 0 ? " indi-form__input--has-error" : "")}
                                style={{ height: '40px' }} type="password"
                                value={confirmPassword} onChange={(event) => handleChange(event)} />
                            <div className="indi-form__input-notification indi-form__input-notification--has-error">{errors.confirmPassword}</div>
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>
                    </fieldset>
                    <div className="btn-submit">
                        {validateForm(errors) &&
                            <button id="submit" className="indi-button indi-button--primary indi-button--small" style={{ minWidth: '160px' }} type="submit">
                                {loading === true ?
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : "Change Password"}
                            </button>}
                        {!validateForm(errors) &&
                            <input id="submit" className="indi-button indi-button--primary indi-button--disabled indi-button--small" type="submit" value="Change Password" />}
                    </div>
                </div>
            }
        </form>
    );
};
export default PasswordExpired;


import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from '../components/loading';
import Header from './header';
import Apps from './anonymous-apps';
import Contact from './contactus';
import config from '../utils/config'

const Index = ({baseUrl }) => {
    const [failed, setFailed] = useState(false);
    const [apps, setApps] = useState({});
    const [returnToLogin, setReturnToLogin] = useState(false);
    useEffect(() => {
        getapps();
    }, []);

    const getapps = async () => {
        try {
            const response = await fetch(`${config.hostApi}home/index`);
            if (response.status !== 200) {
                setFailed(true);
                return;
            }
            const data = await response.json();
            setApps(data);
        }
        catch (err) {
            setFailed(true);
            console.error(err);
        }
    }
    if (returnToLogin) {
        return (<Navigate to={{ pathname: '/login' }} />);
    }
    return (<div>
        {apps === null && <Loader />}
        {apps &&
            <div id="main" role="heading" aria-level="1">
                <Header />
                <div className="indi-row">
                    <div className="indi-col-lg-10 indi-col-lg-offset-1 indi-col-xs-12">
                        <div style={{ marginRight: '2%', color: '#404040' }}>
                            <div>
                                <div className="indi-long-form-text h2">Who am I?</div>
                                <fieldset className="indi-form__fieldset" style={{ backgroundColor: '#ffffff', padding: '0', paddingTop: '3%' }}>
                                    <div className="indi-row">
                                        <div className="indi-col-md-9">
                                            <div className="indi-long-form-text h3">I am a..</div>
                                            <Apps programs={apps} />
                                        </div>
                                        <div className="indi-col-md-3" style={{ paddingTop: '1%', paddingLeft: '5%' }}>
                                            <Contact />
                                        </div>
                                    </div>
                                </fieldset>
                                <button type="button" className="indi-button indi-button--tertiary" onClick={() => setReturnToLogin(true)}> Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="footer-text">
                    <a title="Terms and Conditions (opens in a new window)" href={baseUrl + "terms"} target="_blank">Terms and conditions</a> | &copy;{new Date().getFullYear()} Universal Service Administrative Company. All rights reserved.
                </div>
            </div>
        }
    </div>);
};

export default Index;

import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import config from '../utils/config';
import Icon from '../css/Icon';

const ForgotPassword = () => {
    const [userId, setUserId] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    const [error, setError] = useState('');
    const [returnToLogin, setReturnToLogin] = useState(false);

    const handleReturnToLogin = () => {
        setReturnToLogin(true);
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userId === '') {
            setError('Enter your username.');
            return;
        }
        try {
            setLoading(true);
            var res = await fetch(`${config.hostApi}users/resetpassword`, {
                method: 'POST',
                body: JSON.stringify({ userId }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (res.status !== 200) {
                setFailed(true);
                return;
            }
            const data = await res.json();
            setMessage(data.message);
            setFailed(!data.success);
            setLoading(false);
        }
        catch (err) {
            setFailed(true);
        }
    }
    const handleUserIdChange = (event) => {
        if (event.target.value !== '') {
            setError('');
        }
        setUserId(event.target.value);
    };
    if (returnToLogin === true) {
        return <Navigate to={{ pathname: '/login' }} />;
    }
    let dataToRender
    if (message && failed === false) {
        dataToRender = <div>
            <div className="indi-long-form-text h5 title">Email sent!</div>
            <div className="indi-form__input-row">
                <div className="indi-notification indi-notification--success">
                    <Icon name="success" class="indi-icon" />
                    {message}
                </div>
            </div>
            <div className="indi-form__input-row">&nbsp;</div>
            <div className="btn-submit">
                <input type="submit" className="indi-button indi-button--primary" onClick={() => handleReturnToLogin()} value="Back to Login" />
            </div>
        </div>
    }
    else {
        dataToRender = <div>
            <div className="indi-long-form-text h5 title">Reset Password</div>
            {
                message && <div className="indi-form__input-row">
                    <div className="indi-notification indi-notification--error">
                        <Icon name="warning" class="indi-icon" />
                        {message}
                    </div>
                </div>
            }
            <div className="indi-form__input-row">&nbsp;</div>
            <div className="indi-form__input-row">
                <label htmlFor="UserId" className="indi-form__label">Username</label>
                <input id="UserId" className={"indi-form__input" + (error.length > 0 ? " indi-form__input--has-error" : "")}
                    style={{ height: '40px' }} type="text"
                    value={userId} onChange={handleUserIdChange} />
                <div className="indi-form__input-notification indi-form__input-notification--has-error">{error}</div>
            </div>
            <div className="indi-form__input-row">&nbsp;</div>
            <div className="btn-submit">
                <button id="submit" className="indi-button indi-button--primary" style={{ minWidth: '198px' }} type="submit">
                    {loading === true ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> : "Reset via Email"}
                </button>
            </div>
        </div>
    }
    return (
        <div data-se="auth-container" id="okta-sign-in" className="auth-container main-container no-beacon">
            <div className="okta-sign-in-header auth-header">
                <img src="https://forms.universalservice.org/Indigo/v1.2.0/images/usac-logo.png" className="auth-org-logo" style={{ maxHeight: '55px' }} alt="" />
            </div>
            <div className="auth-content" role="heading" aria-level="1">
                <form onSubmit={handleSubmit}>
                    <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                        {dataToRender}
                    </fieldset>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;



import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';
import Loader from '../components/loading';
import config from '../utils/config';
import Icon from '../css/Icon';
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

const PasswordReset = ({ transactionChange, recoveryToken }) => {

    const { oktaAuth } = useOktaAuth();
    const [error, setError] = useState('');
    const [res, setRes] = useState('');
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({ newPassword: '', confirmPassword: '' });
    const [tokenError, setTokenError] = useState('');
    const [returnToLogin, setReturnToLogin] = useState(false);

    useEffect(() => {
        if (recoveryToken) {
            oktaAuth.verifyRecoveryToken({
                recoveryToken: recoveryToken
            }).then(res => handleRecovery(res))
                .catch(err => setTokenError(err.errorSummary));
        }

    }, [recoveryToken]);

    const handleReturnToLogin = () => {
        setReturnToLogin(true);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'newPassword':
                setErrors((prev) => ({ newPassword: (value.length === 0 ? 'please provide new password.' : ''), ...prev }));
                setNewPassword(value);
                break;
            case 'confirmPassword':
                setErrors((prev) => ({ confirmPassword: (value === newPassword ? '' : "Passwords don't match."), ...prev }));
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (newPassword === '') {
            setErrors({ newPassword: 'Please provide new password.', confirmPassword: 'Please provide confirm password.' });
            return;
        }
        if (validateForm(errors)) {
            setLoading(true);
            res.resetPassword({
                newPassword: newPassword
            }).then(result => transactionChange(result))
                .catch(err => {
                    setError(false);
                    setError(err.errorSummary);
                });
        }
    };

    const handleRecovery = (result) => {
        if (result.status !== config.oktaLoginStates.PASSWORD_RESET) {
            transactionChange(result);
        }
        else {
            setRes(result);
        }
    };

    if (returnToLogin === true) {
        return <Navigate to={{ pathname: '/login' }} />;
    }
    if (tokenError) {
        return (
            <div>
                <fieldset className="indi-form__fieldset">
                    <div className="indi-notification indi-notification--error">
                        <Icon name="warning" class="indi-icon" />
                        {tokenError}
                    </div>
                </fieldset>
                <button name="submit" className="indi-button indi-button--primary indi-button--small" onClick={() => handleReturnToLogin()}>Back to Login</button>
            </div>
        );
    }
    return (
        <form onSubmit={handleSubmit}>
            {res === null && <Loader />}
            {res &&
                <div>
                    <fieldset id="mfa-fieldset" className="indi-form__fieldset">
                        <div className="indi-long-form-text h5 title">Reset your password</div>
                        <div className="desc">
                            <br />
                            <span><strong>Password requirements</strong>: at least {res.policy.complexity.minLength} characters</span>
                            {res.policy.complexity.minUpperCase > 0 && <span>
                                {res.policy.complexity.minUpperCase === 1 ? ", one uppercase" : ", " + res.policy.complexity.minUpperCase + " uppercase"}</span>}
                            {res.policy.complexity.minLowerCase > 0 && <span>
                                {res.policy.complexity.minLowerCase === 1 ? ", one lowercase" : ", " + res.policy.complexity.minLowerCase + " lowercase"}</span>}
                            {res.policy.complexity.minNumber > 0 && <span>
                                {res.policy.complexity.minNumber === 1 ? ", one number" : ", " + res.policy.complexity.minNumber + " numbers"}</span>}
                            {res.policy.complexity.minSymbol > 0 && <span>
                                {res.policy.complexity.minSymbol === 1 ? ", one special character" : ", " + res.policy.complexity.minSymbol + " special characters"}</span>}
                            {res.policy.age.historyCount > 0 && <span> and cannot be any of your last {res.policy.age.historyCount} passwords.</span>}
                        </div>
                        <br />
                        {
                            error && <div className="indi-form__input-row"><br />
                                <div className="indi-notification indi-notification--error">
                                    <Icon name="warning" class="indi-icon" />
                                    {error}
                                </div>
                            </div>
                        }
                        <div className="indi-form__input-row">&nbsp;</div>
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">New password</label>
                            <input aria-label="new password" name="newPassword" className={"indi-form__input" + (errors.newPassword.length > 0 ? " indi-form__input--has-error" : "")} style={{ height: '40px' }} type="password"
                                value={newPassword} onChange={handleChange} />
                            <div className="indi-form__input-notification indi-form__input-notification--has-error">{errors.newPassword}</div>
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>
                        <div className="indi-form__input-row">
                            <label className="indi-form__label">Repeat password</label>
                            <input aria-label="confirm password" name="confirmPassword" className={"indi-form__input" + (errors.confirmPassword.length > 0 ? " indi-form__input--has-error" : "")} style={{ height: '40px' }} type="password"
                                value={confirmPassword} onChange={handleChange} />
                            <div className="indi-form__input-notification indi-form__input-notification--has-error">{errors.confirmPassword}</div>
                        </div>
                        <div className="indi-form__input-row">&nbsp;</div>
                    </fieldset>
                    <div className="btn-submit">
                        {validateForm(errors) && !errors.new &&
                            <button id="submit" className="indi-button indi-button--primary indi-button--small" style={{ minWidth: '160px' }} type="submit">
                                {loading === true ?
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> : "Reset Password"}
                            </button>}
                        {(!validateForm(errors) || errors.new) &&
                            <input id="submit" className="indi-button indi-button--primary indi-button--disabled indi-button--small" type="submit" value="Reset Password" />}
                    </div>
                </div>
            }

        </form>
    );
};

export default PasswordReset;

import React from 'react';

const contact = () => {
    return (
        <div>
            <div className="indi-long-form-text h3">Help?</div>
            <div className="indi-long-form-text">
                <strong>Send us a message</strong> <br />
                <span><a title="Send us a message (opens in new window)" aria-label="Send us a message" href="https://www.usac.org/about/contact-usac/" target="_blank">Click here</a></span> <br />
            </div>
            <div className="indi-long-form-text">
                <strong>Call us</strong> <br />
                <span>(888) 641-8722</span> <br />
            </div>
        </div>
    );
};

export default contact;

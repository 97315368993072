import React from 'react';
import Icon from '../css/Icon';
import { useState } from 'react';

const Banner = () => {
    const [dismiss, setDismiss] = useState(false);
    const handleDismiss = (event) => {
        event.preventDefault();
        setDismiss(true);
    };
    return (
        <div>
            {dismiss === false && <div style={{ margin: '-1.5% -2.8% 0 -2.8%' }}>
                <div className="indi-notification indi-notification--info">
                    <Icon name="information" class="indi-icon" />
                    <a style={{ float: 'right', marginRight: '-20px' }} href="#" onClick={handleDismiss}>
                        <Icon name="close" class="indi-icon" />
                    </a>
                    In accordance with the Supply Chain orders, new certifications have been added to the following forms:
                    RHC - FCC Form 463 and the Telecom invoice, E-rate - FCC Form 473, and High Cost & Lifeline - FCC Form 481.
                    Service providers are required to submit these annual certifications. For additional information, visit the
                    <a title="USAC Supply Chain (opens in a new window)" href="https://www.usac.org/about/reports-orders/supply-chain/" target="_blank"> USAC Supply Chain</a> page.
                </div>
            </div>}
        </div>
    );
};

export default Banner;

import React from 'react';
import Events from '../components/events';
import Apps from '../components/apps';
import Contact from '../components/contactus';
import Banner from '../components/banner';

const Landing = ({ apps }) => {
    return (
        <div style={{ marginTop: '-5%' }}>
            <div className="indi-long-form-text h2" style={{ marginBottom: '12px' }}>Dashboard</div>
            <fieldset className="indi-form__fieldset" style={{ backgroundColor: '#ffffff' }}>
                <Banner />
                <div className="indi-row">
                    <div className="indi-col-md-4 indi-col-lg-3 indi-col-sm-12" style={{ paddingTop: '0.75rem', paddingLeft: '0', paddingRight: '0' }}>
                        <Events eventSource={apps.eventLinks} />
                    </div>
                    <div className="indi-col-md-5 indi-col-lg-6 indi-col-sm-12" >
                        <Apps programs={apps.programs} />
                    </div>
                    <div className="indi-col-md-3 indi-col-lg-3 indi-col-sm-12" style={{ paddingTop: '0.75rem' }}>
                        <div className="indi-col-xs-10 indi-col-xs-offset-2">
                            <Contact />
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    );
};

export default Landing;

const host = document.getElementsByTagName('base')[0].href;
const signInConfig = {
    baseUrl: 'https://usacpreview.okta.com',
    clientId: '0oa1ufurcgxmjFYJG297',
    redirectUri: `${host}implicit/callback`,
    issuer: 'https://usacpreview.okta.com/oauth2/default',
    scopes: ("openid profile email").split(/\s+/)
};
var hostApi = host.split('portal/')[0];
if (host.startsWith("https://forms.universalservice.org")) {
    signInConfig.baseUrl = 'https://usac.okta.com';
    signInConfig.clientId = '0oa2hshgk7yx202Q7297';
    signInConfig.issuer = 'https://usac.okta.com/oauth2/default';
    hostApi = host;
}
else if (host.startsWith("https://forms-uat.universalservice.org")) {
    signInConfig.clientId = '0oa24wbukrjxgMdVZ297';
    hostApi = host;
}
else if (host.startsWith("https://forms-test.universalservice.org")) {
    signInConfig.clientId = '0oa24wa4vbpwRyWrR297';
    hostApi = host;
}
else if (host.startsWith("https://forms-dev.universalservice.org")) {
    signInConfig.clientId = '0oa1w7yy25X4DVeK2297';
    hostApi = host;
}

export default {
    hostApi,
    signInConfig,
    oktaLoginStates: {
        MFA_ENROLL: 'MFA_ENROLL',
        MFA_ENROLL_ACTIVATE: 'MFA_ENROLL_ACTIVATE',
        MFA_CHALLENGE: 'MFA_CHALLENGE',
        MFA_REQUIRED: 'MFA_REQUIRED',
        LOCKED_OUT: 'LOCKED_OUT',
        PASSWORD_WARN: 'PASSWORD_WARN',
        PASSWORD_RESET: 'PASSWORD_RESET',
        PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
        SUCCESS: 'SUCCESS',
        RECOVERY: 'RECOVERY',
        RECOVERY_CHALLENGE: 'RECOVERY_CHALLENGE'
    },

    factors: {
        sms: 'sms',
        email: 'email',
        provider: 'OKTA',
        status: { active: 'ACTIVE', inactive: 'NOT_SETUP' }
    },
};